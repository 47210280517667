import mapImages from '@/util/mapImages'
import Util from '@/util'
import BaseStore from './baseStore'
import OutagesStore from './outagesStore'

const ol = window.ol

class CameraStore extends BaseStore {
  constructor(map, source) {
    super(map, source)
    this.hoveredId = null
    this.visibleConditionRules = {
      groupId: 'LIST',
      status: 'LIST',
      uniqueId: 'STRING',
      disabled: 'BOOLEAN',
      name: 'STRING',
      address: 'STRING'
    }
    this.camerasCounter = {
      cameraOnlineCount: 0,
      cameraOfflineCount: 0
    }
    this.outagesStore = new OutagesStore()
  }

  _createMarker(item) {
    const marker = new ol.Feature({
      geometry: new ol.geom.Point(window.ol.proj.fromLonLat([
        item.longitude, item.latitude
      ])),
      name: item.id,
      population: 4000,
      rainfall: 500,
    });
    item.typeOfItem = 'cameras'
    marker.setId('cameras__' + item.id)
    marker.set('record', item)
    this.markers.set(item.id, marker)

    if (item.status === 'up' || item.status === 'online') {
      this.camerasCounter.cameraOnlineCount++
    } else {
      this.camerasCounter.cameraOfflineCount++
    }

    this._updateMarker(item)
    return marker
  }

  _updateMarker(item) {
    const tmpMarker = this.markers.get(item.id)
    item.statusTitle = Util.statusMap[item.status]
    tmpMarker.setStyle(this._getStyle(item))
    const record = tmpMarker.get('record')
    if (record.status === 'up' && item.status === 'down') {
      this.camerasCounter.cameraOnlineCount--
      this.camerasCounter.cameraOfflineCount++
    }
    if (record.status === 'down' && item.status !== 'down') {
      this.camerasCounter.cameraOnlineCount++
      this.camerasCounter.cameraOfflineCount--
    }
    if (item.latitude !== record.latitude || item.longitude !== record.longitude) {
      tmpMarker.getGeometry().setCoordinates(window.ol.proj.fromLonLat([
        item.longitude, item.latitude
      ]))
    }
    if (record.status !== item.status) {
      this.outagesStore.loadPmi(item.id)
    }
    const visible = this._isVisible(item)
    Object.assign(record, item)
    this.notify(item)
    this._updateVisbility(tmpMarker, visible)
  }

  updateMarker(item) {
    if (!this.markers.get(item.id)) {
      return this._createMarker(item)
    }
    const tmpMarker = this.markers.get(item.id)
    const record = tmpMarker.get('record')
    if (item.status === 'online' && item.status !== record.status) {
      this.camerasCounter.cameraOnlineCount++
      this.camerasCounter.cameraOfflineCount--
    }

    if (record.status === 'online' && item.status !== record.status) {
      this.camerasCounter.cameraOnlineCount--
      this.camerasCounter.cameraOfflineCount++
    }
    this._updateMarker(item)
    return null
  }

  _getStyle(item) {
    let icon = mapImages.camera[this.map.mapMode].down

    if (item.status === 'up') {
      icon = mapImages.camera[this.map.mapMode].up
      if (item.selected) {
        icon = mapImages.camera[this.map.mapMode].up_selected
      }
    } else if (item.selected) {
      icon = mapImages.camera[this.map.mapMode].down_selected
    }
    return this._getIcon(icon, item.name, {
      offsetY: mapImages.camera.offsetY,
      offsetX: mapImages.camera.offsetX
    })
  }

  _getStyleSelected(item) {
    let icon = mapImages.camera[this.map.mapMode].down_selected

    if (item.status === 'up') {
      icon = mapImages.camera[this.map.mapMode].up_selected
    }
    return this._getIcon(icon, item.name, {
      offsetY: mapImages.camera.offsetY,
      offsetX: mapImages.camera.offsetX
    })
  }

  updateMarkersStyle() {
    this.markers.forEach((marker) => {
      marker.setStyle(this._getStyle(marker.get('record')))
    })
  }

  hoverMarker(id) {
    if (!id && this.hoveredId) {
      const marker = this.markers.get(this.hoveredId)
      marker.setStyle(this._getStyle(marker.get('record')))
      this.hoveredId = null
    } else if (id !== this.hoveredId) {
      const marker = this.markers.get(id)
      marker.setStyle(this._getStyleSelected(marker.get('record')))
      this.hoveredId = id
    }
  }
}

export default CameraStore
